export const clientNameFooter = "YOM KIDS";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = ''; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "Yom Kids";
export const nomeFornecedor = "Yom Kids";
export const artigo = "o";
export const artigoCaps = "O";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/yom.kids/",
    profile: "@‌yom.kids"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/@yogacomhistorias4834",
  },
  // {
  //   type: "material-community",
  //   name: "whatsapp",
  //   link: "https://wa.me/message/KWKSZPRGPD2YI1",
  //   number: "(51) 98032-5196"
  // },
];

export const drawerClosedLinks = [
  // {
  //   title: "Fale Comigo",
  //   link: "https://instagram.com",
  // },
];

export const linkAppleStore = "https://apps.apple.com/us/app/yom-kids/id6450434022?platform=iphone"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_yomkids.twa&pli=1"

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [
  {
    title: "Assinar planos",
    link: "https://yomkids.com/",
  },
];
